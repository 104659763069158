import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Loader from './components/loader';
import ProtectedRoute from './utils/protectedRoutes';
import AuthCheck from './routes/authCheck';
import { messaging } from './notifications/firebase';
import { ShowToast } from './components/showToast';
import { onMessage } from 'firebase/messaging';

const LandingPage = React.lazy(()=> import('./pages/home'));
const HustlePage = React.lazy(()=> import('./pages/hustle/hustle_details'));
const HustleUpdatePage = React.lazy(()=> import('./pages/hustle/hustle_update'));
const HustleProposalPage = React.lazy(()=> import('./pages/hustle/hustle_proposal'))
const AuthPage = React.lazy(() => import('./pages/authentication'))
const AccountVerificationPage = React.lazy(() => import('./pages/settings/account_verification'))
const AcoountManagementPage = React.lazy(() => import('./pages/settings/account_management'))
const AccountInfoPage = React.lazy(()=> import('./pages/settings/account_info'))
const CompanyDetailsPage = React.lazy(()=> import('./pages/hustle/hustler_details'))
const MyHustleDetailsPage = React.lazy(()=> import('./pages/hustle/my_hustles'))
const ProjectCreationPage = React.lazy(()=> import('./pages/projects/project_create'))
const ProjectPage = React.lazy(()=> import('./pages/projects/projects'))
const FaqsPage = React.lazy(()=> import('./pages/settings/faqs'))
const MyWalletPage = React.lazy(()=> import('./pages/settings/my_wallet'))
const SupportPage = React.lazy(()=> import('./pages/settings/support'))
const ProjectUpdatePage = React.lazy(()=> import('./pages/projects/project_update'))

const RequesterLandingPage = React.lazy(()=> import('./pages/service_requester_pages/home'))
const BookHustlerPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/book_hustler'))
const ViewHustlerPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/view_hustler'))
const RequesterAddHuslterPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/create_hustle'))
const RequesterHuslterPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/my_hustles'))
const RequesterHustleReviewPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/review_proposal'))
const RequesterMarkHustleReviewPage = React.lazy(()=> import('./pages/service_requester_pages/hustler_details/mark_hustle'))
const SubscriptionPage = React.lazy(()=> import('./pages/settings/subscription_page'))

const CancellationPage = React.lazy(()=> import('./pages/cancellation'))
const SuccessPage = React.lazy(()=> import('./pages/success'))

const App = () => {
  const router = useNavigate()

  onMessage(messaging, (payload) => {
    ShowToast("success", payload.notification.body)
  })

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' || e.key === 'is_ct' || e.key === 'has_verified_id_details' ||
          e.key === 'has_verified_business_details' || e.key === 'email_verified') {
        localStorage.clear()
        router('/')
        return
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[])

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }  

  return (
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route element={<AuthCheck userRole={['hustler']}/>}>
            <Route exact path='/my/hustles' element={<MyHustleDetailsPage/>}></Route>
            <Route exact path='/hustle/proposal' element={<HustleProposalPage/>}></Route>
            <Route exact path='/hustle/review/bookings' element={<HustleProposalPage/>}></Route>
            <Route exact path='/hustle/update/:id' element={<HustleUpdatePage/>}></Route>
            <Route exact path='/portfolio' element={<ProjectPage/>}></Route>
            <Route exact path='/add/portfolio' element={<ProjectCreationPage/>}></Route>
          </Route>

          <Route exact path='/account/info' element={<AccountInfoPage/>}></Route>
          <Route exact path='/account' element={<AcoountManagementPage/>}></Route>
          <Route exact path='/update/portfolio/:id' element={<ProjectUpdatePage/>}></Route>
          <Route exact path='/account/verification' element={<AccountVerificationPage/>}></Route>
          <Route exact path='/my/wallet' element={<MyWalletPage/>}></Route>
          <Route exact path='/subscription/packages' element={<SubscriptionPage/>}></Route>
        </Route>

        <Route exact path='/' element={<LandingPage/>}></Route>
        <Route exact path='/hustle/:id' element={<HustlePage/>}></Route>
        <Route exact path='/company/details' element={<CompanyDetailsPage/>}></Route>
        <Route exact path='/faqs' element={<FaqsPage/>}></Route>
        <Route exact path='/login' element={<AuthPage/>}></Route>

        <Route exact path='/requester/home' element={<RequesterLandingPage/>}></Route>
        <Route exact path='/requester/book/hustler' element={<BookHustlerPage/>}></Route>
        <Route exact path='/requester/view/hustler' element={<ViewHustlerPage/>}></Route>
        <Route exact path='/requester/add/hustle' element={<RequesterAddHuslterPage/>}></Route>
        <Route exact path='/requester/hustles' element={<RequesterHuslterPage/>}></Route>
        <Route exact path='/requester/review/proposal/:id' element={<RequesterHustleReviewPage/>}></Route>
        <Route exact path='/requester/mark/hustle/:id' element={<RequesterMarkHustleReviewPage/>}></Route>

        <Route exact path='/support' element={<SupportPage/>}></Route>
        <Route exact path='/cancellation' element={<CancellationPage/>}></Route>
        <Route exact path='/success' element={<SuccessPage/>}></Route>
      </Routes>
    </React.Suspense>
  );
}

export default App;
