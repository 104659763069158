import { Outlet, Navigate } from "react-router-dom"
import Cookies from "js-cookie";

const AuthCheck = ({userRole}) => {

  const role = Cookies.get('is_ct') 
  let role_type;

  if (role === 'true'){
    role_type = 'service_requester'
  }else{
    role_type = 'hustler'
  }

  const permission_ava = userRole.some(obj => obj === role_type);

  const builder = () => {
    return (
      <Outlet />
    )
  }
  return permission_ava ? builder() : <Navigate to='/error' />
}

export default AuthCheck